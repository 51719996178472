<template>
      <b-row>
        <b-col>
          <b-row>
            <b-col cols="12">
              <span class="autoFillBtnLabel">Medicamentos</span>
              <div class="custom-input-group">
                <div class="custom-input h-38">
                  <div class="custom-input eye-box-container radius-top-e h-38 no-bb with-br">
                    <span>Medicamentos</span>
                  </div>
                  <div class="custom-input eye-box-container h-38 no-bb with-br">
                    <span>1º Mês</span>
                  </div>
                  <div class="custom-input eye-box-container h-38 no-bb with-br">
                    <span>2º Mês</span>
                  </div>
                  <div class="custom-input eye-box-container h-38 no-bb with-br">
                    <span>3º Mês</span>
                  </div>
                  <div class="custom-input eye-box-container h-38 no-bb with-br">
                    <span>Posologia</span>
                  </div>
                  <div class="custom-input eye-box-container radius-top-d h-38 no-bb">
                    <span>Olho(s)</span>
                  </div>
                </div>
                <div class="custom-input">
                    <div class="text-area type-2 no-bb with-br">
                      <input
                          autocomplete="off"
                          type="text"
                          class="form-control text-center"
                          :value="items?.medicamentoOpcao1"
                          :disabled="true"
                      >
                    </div>
                    <div class="text-area type-2 no-bb with-br">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.primeiroMesOpcao1"
                            :disabled="true"
                        >
                    </div>
                    <div class="text-area type-2 no-bb with-br">

                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control text-center"
                                :value="items?.segundoMesOpcao1"
                                :disabled="true"
                            >

                    </div>
                    <div class="text-area type-2 no-bb with-br">
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control text-center"
                                :value="items?.terceiroMesOpcao1"
                                :disabled="true"
                            >
                    </div>
                    <div class="text-area type-2 no-bb with-br">
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control text-center"
                                :value="items?.posologiaOpcao1"
                                :disabled="true"
                            >
                    </div>
                    <div class="text-area type-2 no-bb">
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control text-center"
                                :value="items?.olhoOpcao1"
                                :disabled="true"
                            >
                    </div>
                </div>
                <div class="custom-input no-bb">
                    <div class="text-area type-2 borderB with-br">
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control text-center"
                                :value="items?.medicamentoOpcao2"
                                :disabled="true"
                            >
                  </div>
                  <div class="custom-input no-bb with-br">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.primeiroMesOpcao2"
                            :disabled="true"
                        >
                  </div>
                  <div class="custom-input no-bb with-br">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.segundoMesOpcao2"
                            :disabled="true"
                        >
                  </div>
                  <div class="custom-input no-bb with-br">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.terceiroMesOpcao2"
                            :disabled="true"
                        >
                  </div>
                  <div class="custom-input no-bb with-br">
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control text-center"
                                :value="items?.posologiaOpcao2"
                                :disabled="true"
                            >
                    </div>
                  <div class="text-area type-2 no-bb borderB">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.olhoOpcao2"
                            :disabled="true"
                        >
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="text-area type-2 borderB with-br">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.medicamentoOpcao3"
                            :disabled="true"
                        >
                  </div>
                  <div class="custom-input no-bb with-br">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.primeiroMesOpcao3"
                            :disabled="true"
                        >
                  </div>
                  <div class="custom-input no-bb with-br">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.segundoMesOpcao3"
                            :disabled="true"
                        >
                  </div>
                  <div class="custom-input no-bb with-br">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.terceiroMesOpcao3"
                            :disabled="true"
                        >
                  </div>
                  <div class="custom-input no-bb with-br">
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control text-center"
                                :value="items?.posologiaOpcao3"
                                :disabled="true"
                            >
                    </div>
                  <div class="text-area type-2 borderB">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.olhoOpcao3"
                            :disabled="true"
                        >
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="text-area type-2 borderB with-br">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.medicamentoOpcao4"
                            :disabled="true"
                        >
                  </div>
                  <div class="custom-input no-bb with-br">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.primeiroMesOpcao4"
                            :disabled="true"
                        >
                  </div>
                  <div class="custom-input no-bb with-br">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.segundoMesOpcao4"
                            :disabled="true"
                        >
                  </div>
                  <div class="custom-input no-bb with-br">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.terceiroMesOpcao4"
                            :disabled="true"
                        >
                  </div>
                  <div class="custom-input no-bb with-br">
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control text-center"
                                :value="items?.posologiaOpcao4"
                                :disabled="true"
                            >
                    </div>
                  <div class="text-area type-2 borderB">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control text-center"
                            :value="items?.olhoOpcao4"
                            :disabled="true"
                        >
                  </div>
                </div>
              </div>
              <div class="custom-input">
                  <div>
                    <span class="autoFillBtnLabel">Anamnese</span> 
                  </div>
                  <div>
                    <TextArea
                      autocomplete="off"
                      :value="items?.anamnese"
                      id="anamnese"
                      class="form-control"
                      :disabled="true"
                    />
                  </div>
                </div>
              <div class="laterality-container">
                <span class="autoFillBtnLabel">Paciente realizou tratamento prévio ou está em tratamento da doença</span>
                <div class="laterality-content">
                  <div class="laterality-input">  
                    
                    <span class="autoFillBtnLabel" v-if="items.tratamentoSim">Sim</span>
                    <span class="autoFillBtnLabel" v-if="items.tratamentoNao">Não</span>
                  </div>  
                </div>
                <div class="custom-input">
                  <div>
                    <span class="autoFillBtnLabel">Relatar</span> 
                  </div>
                  <div>
                    <TextArea
                      autocomplete="off"
                      :value="items.relatar"
                      id="relatar"
                      type="text"
                      class="form-control"
                      :disabled="true"    
                    />
                  </div>
                </div>
              </div>
              <div class="laterality-container">
                <span class="autoFillBtnLabel">O paciente é considerado incapaz?</span>
                <div class="laterality-content">
                  <div class="laterality-input"> 
                    <span class="autoFillBtnLabel" v-if="items.incapazSim">Sim</span>
                    <span class="autoFillBtnLabel" v-if="items.incapazNao">Não</span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
  </template>
    
<script>
    export default {
      components: {
        TextArea: () => import('@/components/General/TextArea'),
      },
      props: {
        items: Object,
    },
  
    data() {
      return{
        open: true,
      }
    },

    methods: {
      handleOpen(){
        this.open = !this.open
      }
    },

    }
    </script>
    
  <style lang="scss" scoped>
    .custom-input-group {
      display: flex;
      flex-direction: column;
      border-top: 1px solid var(--neutral-300);
      border-left: 1px solid var(--neutral-300);
      border-right: 1px solid var(--neutral-300);
      border-radius: 8px;
      margin: 24px 5px 16px 5px;
    
      .custom-input {
        flex: 1;
        border: 8px;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid var(--neutral-300);
        justify-content: center;
    
        &.h-38 {
          height: 38px;
        }
    
        .form-control {
          border-radius: 0 !important;
          border-left: 0 !important;
          border-right: 0 !important;
          border-top: 1 solid var(--neutral-300) !important;
  
          &.input {
            justify-items: center;
            align-items: center;
            text-align: center;
          }
  
        }
    
        .border-text {
          border-bottom: 0px !important;
          border-radius: 0px 0px 10px 0px !important;
        }
    
        &.radius-bootom-d {
          border-radius: 0 0 8px 0;
        }
        &.radius-bootom-e {
          border-radius: 0 0 0 8px;
        }
    
        &.no-bb {
          border-bottom: 0;
        }
    
        &.no-br {
          border-right: 0;
        }
    
        &.with-br {
          border-right: 1px solid var(--neutral-300);
        }
    
        &.bbr {
          border-radius: 0 0 8px 0 !important;
        }
    
        .text-area {
          flex: 1;
          line-height: 55px;
          margin-left: 16px;
          font-weight: 400;
          font-size: 16px;
          color: var(--type-active);
    
          .form-control {
            border: 0 !important;
            border-radius: 0 !important;
    
            &.with-bbr {
              border-radius: 0 0 8px 0 !important;
            }
    
            &.with-btr {
              border-radius: 0 8px 0 0 !important;
            }
          }
    
          &.type-2 {
            position: relative;
            margin-left: 0;
            text-align: center;
    
            span {
              width: 100%;
              position: absolute;
              top: -19px;
              left: 0;
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;
              color: var(--type-active);
            }
          }
    
          &.with-br {
            border-right: 1px solid var(--neutral-300) !important;
          }
    
          &.with-brtr {
            border-radius: 0 8px 0 0 !important;
          }
    
          &.with-brbr {
            border-radius: 0 0 8px 0 !important;
          }
        }
      }
    }
    
    .eye-box-container {
      background-color: var(--neutral-100);
      align-items: center;
    
      .eyeValueChange {
        visibility: hidden;
      }
    
      &:hover {
        .eyeValueChange {
          visibility: visible;
        }
      }
    }
    
    .radius-top-d  {
      border-radius: 0 8px 0 0;
    }
    .radius-top-e {
        border-radius: 8px 0 0 0;
    }
    
    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: var(--blue-500);
    }
    
    .laterality-container {
        display: flex;
        flex-direction: column;
        p {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          color: #0C1D59;
        }
        .laterality-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          margin-left: 8px;
          .laterality-input {
            display: flex;
            flex-direction: row;
            gap: 24px;
            .laterality-option {
              display: flex;
              flex-direction: row;
              gap: 12px;
              align-items: center;
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              color: var(--type-active);
              input {
                height: 20px;
                width: 20px;
              }
            }
          }
          .btn {
            width: auto !important;
            height: 38px;
            margin-bottom: 10px;
          }
        }
      }
    
    .blue {
      color: var(--blue-500);
      display: inline-block;
    }
  
    .text-area {
      flex: 1;
      display: flex;
      background-color: var(--neutral-100);
      justify-items: center;
      align-items: center;
  
      line-height: 55px;
      margin-left: 16px;
      font-weight: 400;
      font-size: 16px;
      color: var(--type-active);
  
      &.disabled {
        background-color: #e9ecef !important;
      }
  
      .form-control {
        border: 0 !important;
  
        &.input {
          justify-items: center;
          align-items: center;
          text-align: center;
        }
        
      }
  
      &.type-2 {
        width: 100%;
        position: relative;
        margin-left: 0;
        text-align: center;
        justify-items: center;
        background-color: var(--neutral-000);
  
        span {
          width: 100%;
          position: absolute;
          top: -19px;
          left: 0;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: var(--type-active);
        }
      }
  
      &.borderB {
        border-bottom: 1px solid var(--neutral-300);
      }
    }
    .autoFillBtnLabel {
      margin: 0px 0px 10px 0px;
      line-height: 30px;
      font-weight: 600;
      color: var(--dark-blue);
    }
    .check-disable {
      background-color: var(--neutral-100) !important;
    }
  </style>
    